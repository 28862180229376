<template>
  <div>
    <main class="container-fluid">
      <div class="row mx-md-4">
        <!-- Breadcrumb -->
        <div class="col-md-12 mb-4">
          <div class="card shadow border-0 round">
            <div class="card-body px-2 py-1">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent mb-0">
                  <li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
                  <li class="breadcrumb-item text-secondary">
                    <router-link to="/member/profile" class=" text-decoration-none">Member</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Keranjang Belanja</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <div class="card shadow border-primary round">
            <div class="card-body p-4">
              <h4 class="text-center my-2"><i class="fa fa-shopping-cart mr-3"></i>Keranjang Belanja</h4>
              <hr />
              <div class="text-center my-3" v-if="is_refresh">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div v-else>
                <div class="px-3 py-2" v-if="jumlah_produk_keranjang > 0">
                  <div class="row max-content-user customScroolBar">
                    <div class="col-md-12 mb-3" v-for="(product, index) in produk_keranjang" :key="product.prod_id">
                      <div class="row border round d-flex align-items-center p-3 shadow-sm">
                        <div class="col-md-8">
                          <div class="row d-flex align-items-center">
                            <div class="col-lg-3 p-2 text-center">
                              <router-link :to="'/product-detail/' + product.prod_id + '-' + product.prod_name.toLowerCase()" class="text-decoration-none">
                                <img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top w-75" :alt="product.prod_image" />
                              </router-link>
                            </div>
                            <div class="col-lg-9 text-left px-3">
                              <router-link :to="'/product-detail/' + product.prod_id + '-' + product.prod_name.toLowerCase()" class="text-decoration-none">
                                <h5 class="card-text text-secondary mb-1">{{ product.prod_name | capitalize }}</h5>
                                <p class="text-muted mb-1">{{ product.prod_summary | str_limit(50) }}</p>
                              </router-link>
                              <div class="d-flex justify-content-start align-content-center mb-1" v-if="product.prod_discount && product.prod_discount != 0">
                                <h5 class="text-success mb-1">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency("IDR") }}</h5>
                                <p class="bg-danger text-white round px-2 ml-3 mb-1">{{ product.prod_discount }}%</p>
                                <strike class="text-muted pl-1">{{ product.prod_price | formatCurrency("IDR") }}</strike>
                              </div>
                              <h5 class="text-success mb-1" v-else>{{ product.prod_price | formatCurrency("IDR") }}</h5>
                              <div class="text-muted" v-if="product.prod_stock > 0">
                                <span class="mr-2">{{ product.prod_stock }} Buah</span>
                                <button class="btn btn-success btn-sm disabled">Tersedia &nbsp;<i class="fa fa-check-circle"></i></button>
                              </div>
                              <button class="btn btn-danger btn-sm disabled" v-else>Stok Kosong &nbsp;<i class="fa fa-times-circle"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 my-2 text-center">
                          <p class="text-muted my-0">Jumlah</p>
                          <div class="jumlah">
                            <!-- <button class="minus btn btn-success" @click="check_qty_click(index, -1)" v-if="qty_produk_keranjang[index] > 1">-</button>
														<button class="minus btn btn-success disabled" v-else>-</button>
														<input class="jumlah-produk" type="number" v-model="qty_produk_keranjang[index]" @keyup="check_qty_keyup(index, $event.target.value)" required />
														<button class="plus btn btn-success" @click="check_qty_click(index, 1)" v-if="qty_produk_keranjang[index] < stock_produk_keranjang[index]">+</button>
														<button class="plus btn btn-success disabled" v-else>+</button> -->
                            <button class="minus btn btn-success" @click="check_qty_click(index, -1)" v-if="qty_produk_keranjang[index] > 1">-</button>
                            <button class="minus btn btn-success disabled" v-else>-</button>
                            <input class="jumlah-produk" type="number" v-model="qty_produk_keranjang[index]" @keyup="check_qty_keyup(index, $event.target.value)" required />
                            <button class="plus btn btn-success" @click="check_qty_click(index, 1)">+</button>
                          </div>
                          <button class="btn btn-outline-danger w-75 mt-3" @click="delete_productKeranjang(product.keranjang_id)"><i class="fa fa-trash"></i> &nbsp; Hapus Produk</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-4 text-left border border-warning rounded shadow px-4 py-3" style="height: fit-content;">
                      <h6>Kupon Promo</h6>
                      <form @submit.prevent="cek_kuponPromo">
                        <div class="form align-items-center">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text bg-light">
                                <i class="fa fa-tags text-secondary"></i>
                              </div>
                            </div>
                            <input type="text" v-model.trim="kupon_promo" class="form-control" placeholder="Masukan di sini" v-uppercase />
                            <div class="input-group-append">
                              <button class="btn btn-outline-warning" type="submit">Terapkan!</button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <p class="mb-0 mt-1 text-muted fadeIn d-none" id="ket_kuponPromo"></p>
                    </div>
                    <div class="col-md-5 offset-md-3 text-right border border-success rounded shadow p-3">
                      <div class="text-center" v-if="isLoading && is_refreshJumlah">
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <div v-else class="d-flex align-items-center h-100">
                        <div class="w-100">
                          <h6 class="mb-0">Total Harga ({{ jumlah_produk_keranjang }} Barang)</h6>
                          <h4 class="text-success" id="grand-total-keranjang">{{ product_totalPrice | formatCurrency("IDR") }}</h4>
                          <button class="btn btn-success w-100" @click="checkoutKeranjang()">
                            <h6 class="my-1">Checkout ({{ jumlah_produk_keranjang }} Barang) &nbsp; <i class="fa fa-check"></i></h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center my-3" v-else>
                  <h4 class="text-info mb-1">Oops!</h4>
                  <p class="text-muted">Belum ada Produk dalam Keranjang</p>
                  <router-link to="/product-list?type=product&q=Semua Produk" class="btn btn-outline-primary rounded pb-1"><h5>Ayo Belanja Sekarang!</h5></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      produk_keranjang: [],
      qty_produk_keranjang: [],
      stock_produk_keranjang: [],
      jumlah_produk_keranjang: [],
      kupon_promo: "",
      valid_kupon: "",
      is_refresh: false,
      is_refreshJumlah: false,
    };
  },
  methods: {
    get_productKeranjang() {
      window.axios
        .get("/cart", {
          headers: {
            token: this.$store.getters.token_member,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            this.produk_keranjang = response.data.data;
            this.jumlah_produk_keranjang = this.produk_keranjang.length;
            this.is_refresh = false;
            this.is_refreshJumlah = false;
            this.qty_produk_keranjang = [];
            for (let i = 0; i < this.jumlah_produk_keranjang; i++) {
              this.qty_produk_keranjang.push(this.produk_keranjang[i].keranjang_prod_quantity);
              // this.stock_produk_keranjang.push(this.produk_keranjang[i].prod_stock)

              // SET STOK TIDAK PERNAH HABIS
              this.stock_produk_keranjang.push(99999999);
            }

            $("#grand-total-keranjang").addClass("animate__animated animate__flash");
            setTimeout(() => {
              $("#grand-total-keranjang").removeClass("animate__animated animate__flash");
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update_productKeranjang_onRouteLeave() {
      for (let i = 0; i < this.jumlah_produk_keranjang; i++) {
        if (parseInt(this.produk_keranjang[i].keranjang_prod_quantity) != parseInt(this.qty_produk_keranjang[i])) {
          let formData = new FormData();
          formData.append("_method", "patch");
          formData.append("prod_qty", parseInt(this.qty_produk_keranjang[i]));

          window.axios
            .post("/cart/" + parseInt(this.produk_keranjang[i].keranjang_id), formData, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                token: this.$store.getters.token_member,
              },
            })
            .then((response) => {
              window.NavbarDirtyAccess.get_productKeranjang();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    check_qty_keyup(index, qty) {
      if (qty > 0) {
        if (qty <= this.stock_produk_keranjang[index]) {
          this.$set(this.qty_produk_keranjang, index, qty);
        } else {
          this.$set(this.qty_produk_keranjang, index, this.stock_produk_keranjang[index]);
        }
      } else {
        this.$set(this.qty_produk_keranjang, index, 1);
      }
    },
    check_qty_click(index, qty) {
      let qty_baru = parseInt(this.qty_produk_keranjang[index]) + parseInt(qty);
      if (qty_baru > 0) {
        if (qty_baru <= this.stock_produk_keranjang[index]) {
          this.$set(this.qty_produk_keranjang, index, qty_baru);
        } else {
          this.$set(this.qty_produk_keranjang, index, this.stock_produk_keranjang[index]);
        }
      } else {
        this.$set(this.qty_produk_keranjang, index, 1);
      }
    },
    delete_productKeranjang(id) {
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("prod_qty", 0);

      this.is_refresh = true;
      window.axios
        .delete("/cart/" + id, {
          headers: {
            token: this.$store.getters.token_member,
          },
        })
        .then((response) => {
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop: 83,
              },
              1000,
              "easeInOutExpo"
            );
          this.get_productKeranjang();
          window.NavbarDirtyAccess.get_productKeranjang();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkoutKeranjang() {
      this.is_refresh = true;
      this.update_productKeranjang_onRouteLeave();

      setTimeout(() => {
        let formData = new FormData();
        formData.append("kupon_code", this.valid_kupon);

        // for(let i=0; i<this.produk_keranjang.length; i++) {
        // 	formData.append('keranjang_id[]', this.produk_keranjang[i].keranjang_id);
        // }
        this.$store
          .dispatch("checkout", formData)
          .then(() => {
            this.is_refresh = false;
            this.$router.push("/member/checkout");
          })
          .catch(() => {
            console.log(response);
          });
      }, 1000);
    },
    cek_kuponPromo() {
      this.valid_kupon = "";
      if (this.kupon_promo.toUpperCase() != "") {
        try {
          window.axios
            .get("/kupon?kupon_code=" + this.kupon_promo.toUpperCase(), {
              headers: {
                token: this.$store.getters.token_member,
              },
            })
            .then((response) => {
              this.valid_kupon = this.kupon_promo.toUpperCase();
              $("#ket_kuponPromo").html(
                `
													<p class='text-success mb-0'>Kupon '` +
                  response.data.data.kupon_code +
                  `' : ` +
                  response.data.data.kupon_name +
                  `</p>
													<small class="text-muted">` +
                  response.data.data.kupon_desc +
                  `</small>
												`
              );
              $("#ket_kuponPromo").removeClass("d-none");
            })
            .catch((error) => {
              this.kupon_promo = "";
              $("#ket_kuponPromo").html(`<p class='text-muted mb-0'>Kupon tidak ditemukan!</p>`);
              $("#ket_kuponPromo").removeClass("d-none");
            });
        } catch (e) {
          this.kupon_promo = "";
          $("#ket_kuponPromo").html(`<p class='text-muted mb-0'>Kupon tidak ditemukan!</p>`);
          $("#ket_kuponPromo").removeClass("d-none");
        }
      } else {
        $("#ket_kuponPromo").addClass("d-none");
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    account_type: function() {
      if (this.$store.getters.account_type) {
        return this.$store.getters.account_type;
      }
      return localStorage.getItem("account_type");
    },
    product_totalPrice() {
      let total = 0;
      for (let i = 0; i < this.jumlah_produk_keranjang; i++) {
        total += this.discountedPrice(this.produk_keranjang[i].prod_price, this.produk_keranjang[i].prod_discount) * this.qty_produk_keranjang[i];
      }

      $("#grand-total-keranjang").addClass("animate__animated animate__flash");
      setTimeout(() => {
        $("#grand-total-keranjang").removeClass("animate__animated animate__flash");
      }, 5000);

      return total;
    },
  },
  created() {
    $(document).scrollTop(0);
    this.is_refresh = true;
    this.get_productKeranjang();
    window.addEventListener("beforeunload", this.update_productKeranjang_onRouteLeave);
    window.productKeranjangDirtyAccess = this;
  },
  beforeRouteLeave(to, from, next) {
    this.update_productKeranjang_onRouteLeave();

    if (to.name === "CheckoutMember") {
      // Cek apakah akun adalah akun bisnis?
      if (this.account_type != "personal") {
        // Jika ya, maka akan mengecek data perusahaan
        window.axios
          .get("/me/profile/company", {
            headers: {
              token: this.$store.getters.token_member,
            },
          })
          .then((response) => {
            // Jika apakah data perusahaan masih kosong?
            if (!response.data.data) {
              this.$swal({
                title: "Oops!",
                html: "<p>Mohon lengkapi Data Perusahaan Anda terlebih dahulu</p>",
                icon: "warning",
                timer: 6000,
                showConfirmButton: false,
              });
              this.$router.push("/member/profile#member-company");
            } else {
              next();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        next();
      }
    } else {
      next();
    }
  },
};
</script>
